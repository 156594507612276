import React from "react";
import { graphql } from "gatsby";
import Default from "@hoc/Default";
import { StructuredText } from "react-datocms";
import Title from "@components/Title";
const { formatSocials } = require("../utils");
import {
  renderRule,
  isHeading,
  isParagraph,
  isList,
  isLink,
} from "datocms-structured-text-utils";
import StructuredList from "@components/Structured/StructuredList";
import Paragraph from "@components/Paragraph";
import Button from "@components/Button";

const Policy = ({ pageContext, data }) => {
  const config = data.config;
  config.socials = formatSocials(config);

  return (
    <Default locale={pageContext.locale} config={config}>
      <section className="py-32 md:py-40 pb-48 overflow-hidden bg-darkJungleGreen">
        <div className="w-11/12 mx-auto max-w-[1060px] relative z-30 mb-32">
          <StructuredText
            data={data.policy.policyContent}
            customRules={[
              renderRule(isHeading, ({ node, children, key }) => (
                <Title
                  {...{
                    key,
                    type: node.level,
                    className: `${
                      node.level === 1
                        ? "mb-10 md:mb-14 mt-10 md:mt-14"
                        : node.level === 2
                        ? "mb-7 md:mb-20 mt-10 md:mt-14"
                        : "mb-4 md:mb-5 mt-10 md:mt-14"
                    }`,
                    color: "white",
                  }}
                >
                  {children}
                </Title>
              )),
              renderRule(isParagraph, ({ node, children, key }) => (
                <Paragraph
                  {...{
                    key,
                    color: "slateGrey",
                    className: "mb-7",
                  }}
                >
                  {children}
                </Paragraph>
              )),
              renderRule(isList, ({ node, children, adapter, key }) => (
                <StructuredList
                  {...{
                    node,
                    adapter,
                    key,
                    className:
                      "text-slateGrey max-w-3xl w-11/12 mx-auto mb-12 lg:mb-16",
                  }}
                >
                  {children}
                </StructuredList>
              )),
            ]}
          />
        </div>
        <div className="flex justify-between w-11/12 mx-auto max-w-[1060px] relative z-30 border-t border-slateGrey">
          <Button
            {...{
              to: "/",
              color: "primary",
              link: true,
              external: false,
              className:
                "overflow-hidden text-center group transition-all duration-500 relative flex items-center justify-center mt-6 mr-2 md:mr-6 w-fit mx-auto md:mx-0 md:px-10 py-3 decoration-none uppercase tracking-wider bg-black",
            }}
          >
            Back to home
          </Button>
          <Button
            {...{
              clickHandler: () =>
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                }),
              type: "button",
              color: "white",
              className:
                "overflow-hidden text-center group transition-all duration-500 relative flex items-center justify-center mt-6 ml-2 md:mr-6 w-fit mx-auto md:mx-0 md:px-10 py-3 decoration-none uppercase tracking-wider bg-black",
            }}
          >
            Back to top
          </Button>
        </div>
      </section>
    </Default>
  );
};

export default Policy;

export const query = graphql`
  query getPolicyPage($id: String, $locale: String) {
    policy: datoCmsPolicyPage(id: { eq: $id }, locale: { eq: $locale }) {
      id
      seo {
        description
        title
        twitterCard
        image {
          url
        }
      }
      pageName
      slug
      policyContent {
        value
        blocks
      }
    }
    config: datoCmsGlobalSetting {
      brandingLogoStacked {
        gatsbyImageData(placeholder: BLURRED)
      }
      brandingLogoInline {
        gatsbyImageData(placeholder: BLURRED)
      }
      brandingStudioLogo {
        url
      }
      brandingStudioLogo2 {
        url
      }
      brandingRatingLogo {
        url
      }
      brandingRatingLogo2 {
        url
      }
      brandingSocialFrame {
        gatsbyImageData(placeholder: BLURRED)
      }
      headerNavigationLinks {
        locale
        external
        label
        url
        page {
          ... on DatoCmsPage {
            slug
            model {
              name
            }
          }
          ... on DatoCmsNews {
            slug
            model {
              name
            }
          }
        }
      }
      headerButtons {
        type: linkType
        label
        color: colour
        link {
          ... on DatoCmsPage {
            slug
            model {
              name
            }
          }
          ... on DatoCmsNews {
            slug
            model {
              name
            }
          }
        }
        url
      }
      footerNavigationLinks {
        locale
        external
        label
        url
        page {
          ... on DatoCmsPolicyPage {
            slug
            model {
              name
            }
          }
          ... on DatoCmsPage {
            slug
            model {
              name
            }
          }
          ... on DatoCmsNews {
            slug
            model {
              name
            }
          }
        }
      }
      copyrightStatement

      youtubeIcon {
        url
      }
      youtubeLink

      instagramIcon {
        url
      }
      instagramLink

      twitterIcon {
        url
      }
      twitterLink

      discordIcon {
        url
      }
      discordLink
      steamLogo {
        url
      }
      newsletterBackground {
        gatsbyImageData(placeholder: BLURRED)
      }
      newsletterHeading
      newsletterBody
    }
  }
`;
